<template>
  <div class="page_container">
    <h-banner :bg='banner_url'>
      <div slot="content">
        <div style="color:#fff">
          <div class="banner-title">智能制造解决方案</div>
        </div>
        <p class="banner-desc animate__animated animate__backInRight">以“中智工业智慧管控平台”整体解决方案及MES、集成优化、先进过程控制等细分产品线为切入点，提升客户的生产管控水平及经济效益，持续为客户创造价值</p>
        <!-- 利用技术领先优势，显著 -->
      </div>
    </h-banner>
    <div
      class="page1 page-grey"
      ref="page1"
      id="in1"
    >
      <div class="page1-bg">
        <h-title msg="解决方案"></h-title>
        <div class="page-container">
          <div class="pro animate__animated animate__fadeInUp">
            <!-- <div class="pro-item">
              <div
                class="right icon"
                @click="handleNext"
              >
                <i class="el-icon-right"></i>
              </div>
              <div
                class="left icon"
                @click="handlePre"
              >
                <i class="el-icon-back"></i>
              </div>
            </div> -->
            <el-carousel
              :height="$store.state.isPhone?'2780px':'1170px'"
              arrow="nerver"
              :autoplay="false"
              indicator-position="none"
              ref="page1_ca"
            >
              <el-carousel-item
                v-for="(item,i) in list1"
                :key="i"
              >
                <el-row :gutter="35">
                  <el-col
                    v-for="(item1,i2) in item.list"
                    :key="i2"
                    :style="`margin-bottom:35px;${$store.state.isPhone&&'padding-left:30px;padding-right:30px'}`"
                    class="list_item"
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="8"
                  >
                    <el-card
                      :body-style="{ padding: '0px'}"
                      class="animate__animated animate__fadeInUp"
                    >
                      <el-image
                        fit="cover"
                        :src="item1.banner"
                        class="image"
                        @click="handleClick(item1)"
                      ></el-image>
                      <div
                        style="padding: 14px;"
                        @click="handleClick(item1)"
                      >
                        <span class="title-2">{{item1.title}}</span>
                        <p class="desc-2">{{item1.desc}}</p>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      banner_url: require("../../assets/industry/in-banner.png"),
      list1: [{
        list: [
          {
            title: '智慧园区',
            desc: '以绿色、安全、高效、智能为目标，构建集中管控、安全节能、服务全面配套，企业一站式入驻的综合性智能化园区，通过集中化、智能化管控和精益生产，减少各种物质和能源消耗，减少环境污染，形成科技含量高、经济效益好、资源消耗低、环境污染少的全新发展形态。',
            desc1: '融合智慧园区基础数字化管理和服务；利用大数据、工业（行业）互联网、区块链、人工智能等新一代信息技术，打造产业互联网应用引擎，链接产业全链条、助力企业降本提质增效、赋能园区及企业重塑产业新生态。',
            sc: ['研究院高校', 'CBd商圈', '能源工厂', '数字化小区'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '顶层全面规划', desc: '基于园区业态研究，以问题痛点为应用导向，形成标准体系和指标量化的顶层规划。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '数据智慧应用', desc: '围绕业态业务应用和绿色节能等，形成基于数据智能驱动、高度集成的全周期精细管理、决策和优质服务等智慧应用。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '基于安全可信、云大物智移等多技术融合的园区智能大脑和数字化平台基础设施；实现运营信息化、数智能化、服务平台化、应用移动化的智慧园区数字化发展目标。' },
            ],
            yy: require("../../assets/solution/park/s-sl-yy.png"),
            banner: require("../../assets/solution/park/s-sl-banner.png"),
            imgList: [require("../../assets/solution/park/s-sl-img-1.png"), require("../../assets/solution/park/s-sl-img-2.png"), require("../../assets/solution/park/s-sl-img-4.png"), require("../../assets/solution/park/s-sl-img-3.png")],
          },
          {
            title: '智能工厂',
            desc: '通过DCS、MES等信息系统的全面建设和集成应用，实现计划调度智能化、能源管理智能化、安全环保智能化、生产操作智能化、IT管控智能化，帮助企业优化资源配置，提高生产稳定性，减少安全环保事故，实现产品质量提升、节能降耗、维稳增效的目标。',
            desc1: '全方位助力石油化工、电子、注塑、机械加工、光伏等业态的数字化转型',
            sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '生产车间无纸化', desc: '通过MES系统实现工厂生产车间管理无纸化，实现高效快速的生产管理。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '生产进度数字化', desc: '随时掌控生产进度与生产参数，了解车间生产情况。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '平台架构先进、可信', desc: '实现信息的高效传递、优化处理过程，实时掌控生产数据。' },
            ],
            yy: require("../../assets/solution/factory/s-sl-yy.png"),
            banner: require("../../assets/solution/factory/s-sl-banner.png"),
            imgList: [require("../../assets/solution/factory/s-sl-img-1.png"), require("../../assets/solution/factory/s-sl-img-2.png"), require("../../assets/solution/factory/s-sl-img-3.png"), require("../../assets/solution/factory/s-sl-img-4.png"),],
          },
          {
            title: '智慧LNG',
            desc: '按照工厂全生命周期管理理念，应用物联网、云计算等先进技术，从智慧工程、数字孪生、指挥运营三个层面，构建智慧化的LNG接收站，解决工程建设、生产运营和安全管控瓶颈，实现预测性的业务管控和数字化的工作体验，全方位推动生产运营模式转变，提升运营效率，降低运营成本和管控风险，打造“智慧LNG接收站”标杆。',
            desc1: '大力推动标准化、模块化、信息化，努力建成“全数字化移交、全智能化运营、全生命周期管理”的智慧LNG接收站。',
            sc: ['天然气行业'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '运维管理', desc: '通过事前预警、智能调度等服务功能，提升运维人员应急响应能力，降低设备故障率。增加运维透明度，让客户及时了解设备状态及运维工作进程。通过MES系统实现工厂生产车间管理无纸化，实现高效快速的生产管理。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '在线监测', desc: '采用物联网接入平台，高并发处理技术、多协议自适应技术，批量接入分散各地各类设备。支持大屏展示、web端展示和APP展示，云端呈现设备运行状态、健康状态、设备环境随时随地掌控。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '告警管理', desc: '通过预警、告警的分类及告警等级划分，将告警信息有序梳理，并有序推送到相应责任人。' },
            ],
            yy: require("../../assets/solution/lng/s-sl-yy.png"),
            banner: require("../../assets/solution/lng/s-sl-banner.png"),
            imgList: [require("../../assets/solution/lng/s-sl-img-1.png")],
          },
          {
            title: '智能安全管控',
            desc: '构建企业安全要素辨识（工艺、仪表、电信、电气、设备、配管、安全、信息等专业协同）的数据采集系统，建立以安全状态为核心的企业安全认知的方法与体系，全面、规范、准确的获取安全信息，实现安全信息的采集、存储、分类与融合，建立标准化的企业安全数据库，实现企业安全状态的智能诊断，处置方案的智能匹配及对应急处置合理客观的评价。',
            desc1: '企业安全管理系统,智慧式安全用电管理系统,安全隐患排查系统,多个功能模块,可自定义的数据分析,满足企业内部管理需求,助力企业实现关键人掌控的管理,帮助企业精细化管理..',
            sc: ['污水处理厂', '石化行业', '智慧消防', '安防技术'],
            yy: require("../../assets/solution/com/s-sl-yy.png"),
            banner: require("../../assets/solution/com/s-sl-banner.png"),
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '用电气量监控', desc: '构建用电气量的监控模型，对重点用能企业提供在线监控与预警功能。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '企业能效分析', desc: '获取企业用能数据，提供能效状况分析并为企业提供用能优化建议。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '告警管理', desc: '通过预警、告警的分类及告警等级划分，将告警信息有序梳理，并有序推送到相应责任人。' },
            ],
            imgList: [require("../../assets/solution/com/s-sl-img-1.png"), require("../../assets/solution/com/s-sl-img-3.png"), require("../../assets/solution/com/s-sl-img-4.png"), require("../../assets/solution/com/s-sl-img-2.png"),],
          },
          {
            title: '智能生产管控',
            desc: '通过对企业生产管理、过程控制等信息的处理、分析、优化，建立覆盖企业生产管理与优化业务的综合管控平台，提高对生产过程大量实时数据的监测、分析和预测能力，最终实现生产安全稳定、操作受控运行和运营优化智能的目标，增加高效产品产量，提高产品质量，降低生产消耗和生产经营成本，提高整个炼化业务的运营效率和市场竞争能力。',
            desc1: '',
            sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '安全预警', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '安全监测', desc: '整合企业各种资源，将重大危险源在线监测系统、环境在线监测系统、视频监控系统、安全DCS控制系统及等系统整合为一体化管理平台，发挥资源整合作用。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '实时监控', desc: '实时在线监测监控，实现主要危险源数据的采集与集中监控，具体采集数据信息，根据数据采集模块采集、存储的数据进行监测、整理、报警、联动，实现企业24小时安全应急管理。' },
            ],
            yy: require("../../assets/solution/proc/s-sl-yy.png"),
            banner: require("../../assets/solution/proc/s-sl-banner.png"),
            imgList: [require("../../assets/solution/proc/s-sl-img-1.png"), require("../../assets/solution/proc/s-sl-img-2.png"), require("../../assets/solution/proc/s-sl-img-3.png"), require("../../assets/solution/proc/s-sl-img-4.png")],
          },
          {
            title: '生产优化',
            desc: '石化企业对生产装置的优化正由传统依靠经验的优化向依靠机理建模的模拟优化的方向发展，对生产装置进行机理建模，优化调度异常处置方案、优化生产运行操作参数、发现生产瓶颈、修订工艺卡片，最终提升目的产品收率，降低装置物耗和能耗，提高企业经济效益 。',
            desc1: '',
            sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
            yy: require("../../assets/solution/op/s-sl-yy.png"),
            banner: require("../../assets/solution/op/s-sl-banner.png"),
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '生产可视化', desc: '进度汇报自动关联生产数据，一键查看生产订单所有执行过程（子单合集），实时跟踪、掌控订单进度，及时调整、安排生产活动，进度一目了然。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '生产质量控制', desc: '供生产质检和委外质检，自定义设置质检字段、编号、类型、等级，支持质检单跟进、合格品入库、不合格品返修，降低废品率，为企业节约成本。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '生产资源集成', desc: '建立规范、统一、完整的生产数据库，各区域、各系统、各环节数据互联互通，实时掌控生产动态，快速提升协作效率，有效预防生产问题和故障。' },
            ],
            imgList: [require("../../assets/solution/op/s-sl-img-1.png"), require("../../assets/solution/op/s-sl-img-2.png"), require("../../assets/solution/op/s-sl-img-3.png"), require("../../assets/solution/op/s-sl-img-4.png")],
          },
          {
            title: '实验室信息管理',
            desc: '以ISO/IEC17025、CNAS等相关法规和标准要求为准则，集检验流程管理、实验室资源管理、质量数据管理等功能为一体的实验室信息管理平台，实现实验数据的自动化采集，化验流程的信息化管理，为企业的质量控制和生产优化提供及时可靠的数据依据和决策支持，整体提升企业质量管理的效率和精细化水平。',
            desc1: '针对实验室研发设计，并采用科学的管理思想和先进的数据库技术，来实现以实验室为核心的研发型整体的全方位管理。',
            sc: ['石油化工', '金属冶炼', '食药检测', '医学检测'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '检验流程管理', desc: '样品计划、样品标签、接收指派、留样管理、仪器数据采集、结果录入、结果审核' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '实验室资源管理', desc: '人员管理、人员考核、仪器管理、材料管理、方法标准管理、环境管理' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '质量数据管理', desc: '样品查询、样品报告、分析进度跟踪、数据展示分析' },
            ],
            yy: require("../../assets/solution/lab/s-sl-yy.png"),
            banner: require("../../assets/solution/lab/s-sl-img-banner.png"),
            imgList: [require("../../assets/solution/lab/s-sl-img-1.png"), require("../../assets/solution/lab/s-sl-img-2.png"), require("../../assets/solution/lab/s-sl-img-3.png"), require("../../assets/solution/lab/s-sl-img-4.png")],
          },
          {
            title: '安环应急指挥',
            desc: '建立、健全企业安环应急指挥体系和对突发事件预警和应急保障机制，通过安环应急指挥系统的建设，实现事故预测预判和风险有效防控，推动安监工作从被动响应向主动预见转变，从经验决策向基于大数据的科学决策转变，帮助企业有效应对各种突发事件和自然灾害等紧急状况，提高应急处置的解决能力。',
            desc1: '企业安全管理系统,智慧式安全用电管理系统,安全隐患排查系统,多个功能模块,可自定义的数据分析,满足企业内部管理需求,助力企业实现关键人掌控的管理,帮助企业精细化管理..',
            sc: ['物联监控', '舆论管控', '资源调度', '环境监测'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '安环监控', desc: '整合企业各种资源，将重大危险源在线监测系统、环境在线监测系统、视频监控系统、安全DCS控制系统及等系统整合为一体化管理平台，发挥资源整合作用。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '设备预测', desc: '及时预测预警，建立企业安全生产、环保指标数据库，超出监测系统预警上下限值自动报警，指挥中心根据情况实时反馈企业安全环保应急管理部门。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '告警管理', desc: '实时在线监测监控，实现主要危险源数据的采集与集中监控，具体采集数据信息，根据数据采集模块采集、存储的数据进行监测、整理、报警、联动，实现企业24小时安全应急管理' },
            ],
            yy: require("../../assets/solution/their/s-sl-yy.png"),
            banner: require("../../assets/solution/their/s-sl-banner.png"),
            imgList: [require("../../assets/solution/their/s-sl-img-1.png"), require("../../assets/solution/their/s-sl-img-2.png"), require("../../assets/solution/their/s-sl-img-3.png"), require("../../assets/solution/their/s-sl-img-4.png")],
          },
          {
            title: '企业运营大数据',
            desc: '依托企业信息化系统及基础设施，集成ERP、PHD、MES、LIMS等信息系统，构建企业ODS集成平台；基于数据模型分析技术，从装置加工、设备运行、质量管控、消防安防、环保监测、生产经营等业务领域进行场景建模，对海量数据层层挖掘，深入分析业务运行的关键指标，从而增强管理决策层业务聚焦，提升企业生产经营决策、调度指挥的智能化水平。',
            desc1: '',
            sc: ['石油、石化工厂', '流程制造业', '热加工行业', '半导体制造'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '发掘潜在数据', desc: '大数据处理平台的基础上，结合企业实际情况，帮助企业进行主数据建设、数据质量改善等数据的优化治理，在海量的数据中发掘有价值的知识信息。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '企业能效分析', desc: '分析解决方案是为集团型企业用户提供的全面、高效的商业分析整体解决方案。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '告警管理', desc: '帮助企业进行主数据建设、数据质量改善等数据的优化治理，建立数据仓库体系结构；通过高效的商业分析平台，借助成熟的商业分析模型及分析主题，帮助企业在海量的数据中发掘有价值的知识信息。' },
            ],
            yy: require("../../assets/solution/oper/s-sl-yy.png"),
            banner: require("../../assets/solution/oper/s-sl-banner.png"),
            imgList: [require("../../assets/solution/oper/s-sl-img-1.png"), require("../../assets/solution/oper/s-sl-img-2.png"), require("../../assets/solution/oper/s-sl-img-3.png"), require("../../assets/solution/oper/s-sl-img-4.png"),],
          },
          {
            title: '智慧油气田',
            desc: '利用物联网技术，建立覆盖油气举升、油气集输（产量计量）、油气水处理与油气储运等油气生产全过程业务管理系统，应用生产数据自动采集、远程监控、实时诊断、生产预警等功能，实现生产方式的变革＼生产效率的提高、管理水平的提升。其业务范围覆盖。',
            desc1: '',
            sc: ['石油、石化工厂', '燃气', '煤', '海油'],
            wt: [
              { icon: require("../../assets/industry/i-page2-1.png"), step: 'STEP1', title: '物联网远程监控', desc: '通过现场完善的监视、检测设备，实时监测井、间、站场、管网、电力系统设备工况及故障，及时发现异常情况，进行多级预警与报警，并进行及时、有效地处理，保障生产过程的安全性、稳定性。' },
              { icon: require("../../assets/industry/i-page2-2.png"), step: 'STEP2', title: '优化生产工艺流程', desc: '对实时采集的生产数据和生产过程产生的业务数据进行汇总、统计、挖掘、分析，为远程生产指挥、生产应急调度、生产工艺流程模拟仿真及优化运行、节能降耗等提供科学的决策依据。' },
              { icon: require("../../assets/industry/i-page2-3.png"), step: 'STEP3', title: '故障远程推送', desc: '满足企业经营管理人员、现场生产人员、设备维护人员、安全管理人员随时随地了解重要信息。' },
            ],
            yy: require("../../assets/solution/ga/s-sl-yy.png"),
            banner: require("../../assets/solution/ga/s-sl-banner.png"),
            imgList: [require("../../assets/solution/imoo/s-Imoo-img-1.png"), require("../../assets/solution/imoo/s-Imoo-img-3.png"), require("../../assets/solution/imoo/s-Imoo-img-2.png"), require("../../assets/solution/imoo/s-Imoo-img-4.png")],
          },

        ],
      }
      ],
    }
  },
  mounted() {
  },
  methods: {
    handlePre() {
      this.$refs['page1_ca'].prev()
    },
    handleNext() {
      this.$refs['page1_ca'].next()
    },
    handleClick(item) {
      this.$router.push({ path: '/solution', query: { key: item.title } });
      localStorage.setItem('CISSLUTIONINFO', item.title)
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner-title {
    font-size: 2.2em;
    width: 980px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 35px;
  }
  .banner-desc {
    width: 980px;
    text-align: center;
    color: #fff;
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner-title {
    font-size: 1.2em;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 35px;
  }
  .banner-desc {
    font-size: 0.825em;
    text-align: center;
    color: #fff;
    padding: 0 20px;
  }
}
.page_container {
  background-color: #fff;

  .banner-bnt {
    width: 104px;
    height: 26px;
    background: #264bf8;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    margin-top: 25px;
    cursor: pointer;
  }
  .page1 {
    padding-bottom: 55px;
    .page1-bg {
      background: url("../../assets/industry/i-dot2.png") no-repeat 80% bottom;
      background-size: 600px;
    }

    .page-container {
      .pro-item {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 30px;
        .icon {
          padding: 4px;
          border: 1px solid #1f2022;
          margin-right: 15px;
          &:hover {
            background-color: rgba(38, 75, 248, 1);
            color: #fff;
            border: 1px solid rgba(38, 75, 248, 1);
            cursor: pointer;
          }
        }
      }
    }
    .home5-content {
      display: flex;
      flex-direction: row;
    }
    .home5-left-title {
      width: 230px;
      background-color: #f4f4f4;
      text-align: center;
      line-height: 45px;
      color: #848585;
      cursor: pointer;
    }
    .active {
      background-color: #264bf8;
      color: #fff;
    }

    .title-2 {
      font-size: 0.85em;
    }

    .desc-2 {
      font-size: 0.625em;
      color: #60727e;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }

    .image {
      width: 100%;
      height: 160px;
      display: block;
    }
    .list_item:hover {
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
      transition: all 0.4s ease-in-out;
      cursor: pointer;
    }
  }
}
</style>